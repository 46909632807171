import React, {useEffect, useState} from "react";
import axios from "../../apis/AxiosInstance";
import {useDispatch, useSelector} from "react-redux";
import useAxiosFunction from "../../hook/AxiosHook";
import {showDateInHeader} from "../../reducer/ShowDateReducer";
import {getDateInUTC, getDateOnly, getWeeksBetweenDatesEndATSat} from "../../commom/DateFormat";
import {
    getAllArrayValue, getArrayValue,
    getItemByPlantDate,
    getReportName, getSelectedAllFlag,
    searchArray,
    searchArrayTotal, searchArrayWithActive, searchArrayWithActiveAndShow,
    specificArrayData, updateMultiSelectSearch
} from "../../commom/CommonFunction";
import Modal from "react-bootstrap/Modal";
import DataExportForm from "./DataExportForm";
import {exportSurveyData} from "../../commom/ExportToExcel";
import {useOuterClick} from "../../hook/VisibilityHook";
import {
    getClientStatReport, getLinenQualityReport, getOnSiteReport,
    masterReportData,
    scorecardReportData,
    surveyReportsData,
    utilizationReportData
} from "../../commom/CommonReportFunction";
import Loader from "../../commom/LoaderComponent";
import {validateForm, validateFormOnSave} from "../../commom/ValidateFormData";
import Message from "../../commom/Message";

const DataExport = (props) => {
    const dispatch = useDispatch();
    const idnAffiliation = useSelector((state) => state.userState.user.idnAffiliation);
    const plantData = useSelector((state) => state.plantReducer);
    const siteData = useSelector((state) => state.siteReducer);
    const [response, error, loading, axiosFetch] = useAxiosFunction();
    const frequencyData = useSelector((state) => state.frequencyReducer);
    const [utilizationList, setUtilizationList] = useState();
    const [primaryTextileList, setPrimaryTextileList] = useState();
    const [stateList, setStateList] = useState();
    const [volumeList, setVolumeList] = useState();
    const [escalationFlagList, setEscalationFlagList] = useState();
    const [bedTypeList, setBedTypeList] = useState();
    const [facilityTypeList, setFacilityTypeList] = useState();
    const [financialList, setFinancialList] = useState();
    const [traumaLevelList, setTraumaLevelList] = useState();
    const [linenContractLiasonList, setLinenContractLiasonList] = useState();
    const [linenDistMgmtList, setLinenDistMgmtList] = useState();
    const [evsList, setEvsList] = useState();
    const [usesLdSoftwareList, setUsesLdSoftwareList] = useState();
    const [gpoUtilizedList, setGpoUtilizedList] = useState();
    const [locationList, setLocationList] = useState();
    const [facilityGeographyList, setFacilityGeographyList] = useState();
    const [linenServiceList, setLinenServiceList] = useState();
    const [fnList, setFnList] = useState();
    const [patientTransportList, setPatientTransportList] = useState();
    const [plantOpMgmtList, setPlantOpMgmtList] = useState();
    const [clinicalEngList, setClinicalEngList] = useState();
    const [idnList, setIdnList] = useState();
    const [reportOptionList, setReportOptionList] = useState();
    const [plantList, setPlantList] = useState("");
    const [siteList, setSiteList] = useState("");
    const [sitesList, setSitesList] = useState("");
    const [itemList, setItemList] = useState("");
    const startDate = useSelector((state) => state.dateRangeReducer.startDate);
    const endDate = useSelector((state) => state.dateRangeReducer.endDate);
    const [formValue, setFormValue] = useState({
        showPlant: false,
        selectAllPlant: false,
        plantCount: 0,
        showSite: false,
        selectAllSite: false,
        siteCount: 0,
        idnAffiliation: "",
        showBedType: false,
        selectAllBedType: false,
        bedTypeCount: 0,
        showClinicalEng: false,
        selectAllClinicalEng: false,
        clinicalEngCount: 0,
        showEvs: false,
        selectAllEvs: false,
        evsCount: 0,
        showFacilityGeo: false,
        selectAllFacilityGeo: false,
        facilityGeoCount: 0,
        showFacilityType: false,
        selectAllFacilityType: false,
        facilityTypeCount: 0,
        showFinancial: false,
        selectAllFinancial: false,
        financialCount: 0,
        showFN: false,
        selectAllFN: false,
        fNCount: 0,
        showGpoUtil: false,
        selectAllGpoUtil: false,
        gpoUtilCount: 0,
        showLdSoftware: false,
        selectAllLdSoftware: false,
        ldSoftwareCount: 0,
        showLinenCont: false,
        selectAllLinenCont: false,
        linenContCount: 0,
        showLinenDist: false,
        selectAllLinenDist: false,
        linenDistCount: 0,
        showLinenService: false,
        selectAllLinenService: false,
        linenServiceCount: 0,
        showLocation: false,
        selectAllLocation: false,
        locationCount: 0,
        showPatientTransport: false,
        selectAllPatientTransport: false,
        patientTransportCount: 0,
        showPlantOpe: false,
        selectAllPlantOpe: false,
        plantOpeCount: 0,
        showPrimaryTextile: false,
        selectAllPrimaryTextile: false,
        primaryTextileCount: 0,
        showState: false,
        selectAllState: false,
        stateCount: 0,
        showTraumaLevel: false,
        selectAllTraumaLevel: false,
        traumaLevelCount: 0,
        showUtilization: false,
        selectAllUtilization: false,
        utilizationCount: 0,
        showVolume: false,
        selectAllVolume: false,
        volumeCount: 0,
        showEscalationFlag: false,
        selectAllEscalationFlag: false,
        escalationFlagCount: 0,
        showReportOption: false,
        selectAllReportOption: false,
        reportOptionCount: 0,
        numOfBeds: 0,
        renewStartDate: new Date(),
        renewEndDate: new Date()
    });
    const reportOption = useSelector((state) => state.reportOptionReducer.option);
    const [disableSubmit, setDisableSubmit] = useState(true);

    const innerRef = useOuterClick(e => {
        setFormValue({...formValue, showPlant: false});
    });
    const innerRefSite = useOuterClick(e => {
        setFormValue({...formValue, showSite: false});
    });
    const innerRefBedType = useOuterClick(e => {
        setFormValue({...formValue, showBedType: false,});
    });
    const innerRefClinicalEng = useOuterClick(e => {
        setFormValue({...formValue, showClinicalEng: false});
    });
    const innerRefEvs = useOuterClick(e => {
        setFormValue({...formValue, showEvs: false});
    });
    const innerRefFacilityGeo = useOuterClick(e => {
        setFormValue({...formValue, showFacilityGeo: false});
    });
    const innerRefFacilityType = useOuterClick(e => {
        setFormValue({...formValue, showFacilityType: false});
    });
    const innerRefFN = useOuterClick(e => {
        setFormValue({...formValue, showFN: false});
    });
    const innerRefFinancial = useOuterClick(e => {
        setFormValue({...formValue, showFinancial: false});
    });
    const innerRefGpoUtil = useOuterClick(e => {
        setFormValue({...formValue, showGpoUtil: false});
    });
    const innerRefLdSoftware = useOuterClick(e => {
        setFormValue({...formValue, showLdSoftware: false});
    });
    const innerRefLinenCont = useOuterClick(e => {
        setFormValue({...formValue, showLinenCont: false});
    });
    const innerRefLinenDist = useOuterClick(e => {
        setFormValue({...formValue, showLinenDist: false});
    });
    const innerRefLinenService = useOuterClick(e => {
        setFormValue({...formValue, showLinenService: false});
    });
    const innerRefLocation = useOuterClick(e => {
        setFormValue({...formValue, showLocation: false});
    });
    const innerRefPatientTransport = useOuterClick(e => {
        setFormValue({...formValue, showPatientTransport: false});
    });
    const innerRefPlantOpe = useOuterClick(e => {
        setFormValue({...formValue, showPlantOpe: false});
    });
    const innerRefPrimaryTextile = useOuterClick(e => {
        setFormValue({...formValue, showPrimaryTextile: false});
    });
    const innerRefState = useOuterClick(e => {
        setFormValue({...formValue, showState: false});
    });
    const innerRefTraumaLevel = useOuterClick(e => {
        setFormValue({...formValue, showTraumaLevel: false});
    });
    const innerRefUtilization = useOuterClick(e => {
        setFormValue({...formValue, showUtilization: false});
    });
    const innerRefVolume = useOuterClick(e => {
        setFormValue({...formValue, showVolume: false});
    });
    const innerRefEscalationFlag = useOuterClick(e => {
        setFormValue({...formValue, showEscalationFlag: false});
    });
    const innerRefReportOption = useOuterClick(e => {
        setFormValue({...formValue, showReportOption: false});
    });
    const [inputValidation, setInputValidation] = useState({
        reportOptionCount: true
    });
    const fieldValidation = {
        reportOptionCount: "digit",
    };
    const [formValidation, setFormValidation] = useState(true);
    const [showMessage, setShowMessage] = useState(false);
    const [reportDropdown, setReportDropdown] = useState("");
    const [traumaLevelAllList, setTraumaLevelAllList] = useState("");
    const surveyData = useSelector((state) => state.SurveyReducer);

    useEffect(() => {
        dispatch(showDateInHeader(true));
        setPlantList(updateMultiSelectOption(plantData, "plant"));
        setBedTypeList(updateMultiSelectFilteredOption(frequencyData, "Type of Beds"));
        setClinicalEngList(updateMultiSelectFilteredOption(frequencyData, "Clinical Engineering"));
        setPlantOpMgmtList(updateMultiSelectFilteredOption(frequencyData, "Plant Operations Mgmt"));
        setPatientTransportList(updateMultiSelectFilteredOption(frequencyData, "Patient Transport"));
        setFnList(updateMultiSelectFilteredOption(frequencyData, "F&N"));
        setLinenServiceList(updateMultiSelectFilteredOption(frequencyData, "Linen Service"));
        setFacilityGeographyList(updateMultiSelectFilteredOption(frequencyData, "Facility Geography"));
        setLocationList(updateMultiSelectFilteredOption(frequencyData, "Location"));
        setGpoUtilizedList(updateMultiSelectFilteredOption(frequencyData, "GPO Utilized"));
        setUsesLdSoftwareList(updateMultiSelectFilteredOption(frequencyData, "Uses LD Software"));
        setEvsList(updateMultiSelectFilteredOption(frequencyData, "EVS"));
        setLinenDistMgmtList(updateMultiSelectFilteredOption(frequencyData, "Linen Dist Mgmt"));
        setLinenContractLiasonList(updateMultiSelectFilteredOption(frequencyData, "Linen Contract Liason"));
        setTraumaLevelList(updateMultiSelectFilteredOption(frequencyData, "Trauma Level"));
        setFinancialList(updateMultiSelectFilteredOption(frequencyData, "Financial"));
        setFacilityTypeList(updateMultiSelectFilteredOption(frequencyData, "Type of Facilities"));
        setEscalationFlagList(updateMultiSelectFilteredOption(frequencyData, "Escalation Flag"));
        setStateList(updateMultiSelectFilteredOption(frequencyData, "State"));
        setPrimaryTextileList(updateMultiSelectFilteredOption(frequencyData, "Primary Textile"));
        setUtilizationList(updateMultiSelectFilteredOption(frequencyData, "Utilization"));
        setVolumeList(updateMultiSelectFilteredOption(frequencyData, "Volume"));
        setItemList((frequencyData).filter(value => value.type === "Item Category"));
        setReportOptionList(updateMultiSelectFilteredOptionForReport(frequencyData, "Report Option", props.surveysArray));
        setReportDropdown(updateMultiSelectFilteredOptionForReport(frequencyData, "Report Option", props.surveysArray));
        setIdnList([{"value": idnAffiliation}]);
        setTraumaLevelAllList(updateMultiSelectFilteredOption(frequencyData, "Trauma Level"));
    }, [props.surveysArray, frequencyData, plantData]);

    const getReportForPlantQASurvey = async () => {
        const response = await axiosFetch({
            axiosInstance: axios,
            method: 'post',
            url: '/getPlantQualityAuditByDates',
            requestConfig: {
                data: {
                    "plant": specificArrayData(plantData, "_id"),
                    "startDate": getDateInUTC(startDate),
                    "endDate": getDateInUTC(startDate),
                }
            }
        });
        let plantArr = []; // add unique housecode present in the fetched data
        let plantArray = []; // create new array for table insertion
        let itemArray = [];
        let qaDetail = [];
        let plantsData = [];
        let dateRangeArray = getWeeksBetweenDatesEndATSat(getDateOnly(startDate), getDateOnly(endDate));

        if (response) {
            // fetched data array
            response.data.forEach((obj) => {
                let plantObject = Object.assign({}, obj);
                // Add unique object in plantArray with new key as "chart"
                if (plantArr.indexOf(plantObject.houseCode) < 0) {
                    plantArr.push(plantObject.houseCode);
                    plantsData.push(plantObject.plant);
                }
            });

            response.data.forEach((plant) => {
                let plantObject = Object.assign({}, plant);
                let inspected = parseInt(plantObject.inspected);
                let imperfections = parseInt(plantObject.imperfections);
                let percent = Math.ceil((imperfections * 100) / inspected);
                let objectData = searchArray(plantObject.plant, plantData, "_id");

                let obj = {
                    "Division": objectData.division,
                    "Plant": plantObject.plant,
                    "Last 7 Days%": 0,
                    "#Consecutive Weeks Over 15%": 0,
                    "#Weeks Over 15%": 0
                };

                qaDetail.push(obj);
            });

            itemList.forEach((item, i) => {
                let data = [];
                data.push(item.value);
                plantArr.forEach((plant, j) => {
                    let getData = searchArrayTotal(response.data, "plantHc", "itemCategory", plant, item.value);
                    data.push(getData.inspected);
                    data.push(getData.imperfections);
                    data.push(getData.percent);
                });

                let objData = Object.assign({}, data);
                plantArray.push(objData);
            });

            response.data.forEach((obj) => {
                let plantObject = Object.assign({}, obj);
                let data = [];

                data.push(plantObject.plantHc);
                data.push(plantObject.plant);
                data.push(plantObject.itemCategory);
                dateRangeArray.forEach((dateValue) => {
                    let getData = getItemByPlantDate(response.data, dateRangeArray, plantObject.plant, plantObject.itemCategory, dateValue);

                    data.push(getData.inspected);
                    data.push(getData.imperfections);
                    data.push(getData.imperfections);
                });

                let objData = Object.assign({}, data);
                itemArray.push(objData);
            });

            /*let totalArray = [];
            plantArr.forEach(objData => {
                let data = [];
                plantArr.forEach((plant, j) => {
                    let getData = searchArrayTotal(itemArray, "plantHc", plant);
                    data.push(getData.inspected);
                    data.push(getData.imperfections);
                    data.push(getData.percent);
                });

                let objData = Object.assign({}, data);
                plantArray.push(objData);
            });*/
        }

        let newArray = {
            "qaDetail": qaDetail,
            "itemSummaryByPlant": plantArray,
            "plantImperfectionSummary": itemArray,
            "plantArray": plantsData,
            "dateRange": dateRangeArray
        };
    };

    const getSites = (plantName) => {
        let sites = [];

        if (plantName && plantName.length) {
            siteData.forEach(obj => {
                let plantObj = Object.assign({}, obj);
                if (plantName.indexOf(plantObj.plant) !== -1) {
                    sites.push({
                        label: plantObj.site,
                        value: false
                    });
                }
            });
        }

        return sites;
    };

    const updateMultiSelectOption = (fetchedValue, label) => {
        let filteredArray = fetchedValue.map((obj) => {
            let data;

            if (label === "plant") {
                data = obj._id;
            } else {
                data = obj.value;
            }

            return {
                label: data,
                value: false
            };
        });

        if(filteredArray.length) {
            filteredArray.sort(function (a, b) {
                return (a["label"]).localeCompare(b["label"])
            });

            return filteredArray;
        } else {
            return "";
        }
    };

    const updateMultiSelectFilteredOptionForReport = (fetchedValue, filterValue, surveyData) => {
        let filteredArray = [];
        let reportArray = [];
        let surveys = surveyData;
        let reports = [];
        fetchedValue.forEach((obj) => {
            let data = Object.assign({}, obj);

            if (data.type === filterValue) {
                reports.push(data.value);
            }
        });

        if (reports && reports.length) {
            reports.forEach((value) => {
                if (value == 'Activity Raw Data') {
                    let survey = searchArrayWithActiveAndShow("Activities", surveys, "title", "active", "showOnReport");
                    if(survey) {
                        reportArray.push("Activity Raw Data");
                    }
                } else if (value == 'Add On Sales Data') {
                    let survey = searchArrayWithActiveAndShow("Add On Sales", surveys, "title", "active", "showOnReport");
                    if(survey) {
                        reportArray.push("Add On Sales Data");
                    }
                } else if (value == 'Client Sat Raw Data') {
                    let survey = searchArrayWithActiveAndShow("Client Satisfaction", surveys, "title", "active", "showOnReport");
                    if(survey) {
                        reportArray.push("Client Sat Raw Data");
                    }
                } else if (value == 'Linen Quality & Availability Raw Data') {
                    let survey = searchArrayWithActiveAndShow("Linen Quality & Availability", surveys, "title", "active", "showOnReport");
                    if(survey) {
                        reportArray.push("Linen Quality & Availability Raw Data");
                    }
                } else if (value == 'Linen Awareness Data') {
                    let survey = searchArrayWithActiveAndShow("Linen Awareness", surveys, "title", "active", "showOnReport");
                    if(survey) {
                        reportArray.push("Linen Awareness Data");
                    }
                } else if (value == 'Linen Committee Data') {
                    let survey = searchArrayWithActiveAndShow("Linen Committee", surveys, "title", "active", "showOnReport");
                    if(survey) {
                        reportArray.push("Linen Committee Data");
                    }
                } else if (value == 'Linen Loss Raw Data') {
                    let survey = searchArrayWithActiveAndShow("Linen Loss", surveys, "title", "active", "showOnReport");
                    if(survey) {
                        reportArray.push("Linen Loss Raw Data");
                    }
                } else if (value === 'Master Data Raw Data') {
                    reportArray.push("Facility Master Data");
                } else if (value == 'PAR Raw Data') {
                    let survey = searchArrayWithActiveAndShow("PAR", surveys, "title", "active", "showOnReport");
                    if(survey) {
                        reportArray.push("PAR Raw Data");
                    }
                } else if (value == 'Onsite Linen Inspection Raw Data') {
                    let survey = searchArrayWithActiveAndShow("Onsite Linen Inspection", surveys, "title", "active", "showOnReport");
                    if(survey) {
                        reportArray.push("Onsite Linen Inspection Raw Data");
                    }
                } else if (value == 'QBR Data') {
                    let survey = searchArrayWithActiveAndShow("QBR", surveys, "title", "active", "showOnReport");
                    if(survey) {
                        reportArray.push("QBR Data");
                    }
                } else if (value === 'Scorecard') {
                    //reportArray.push("Scorecard");
                } else if (value === 'Utilization') {
                    //reportArray.push("Utilization");
                } else if (value == 'Sub Contractor Survey Data') {
                    let survey = searchArrayWithActiveAndShow("Subcontractor End User", surveys, "title", "active", "showOnReport");
                    if(survey) {
                        reportArray.push("Sub Contractor Survey Data");
                    }
                } else {
                    let surveyTitle = value.replace(/\s*Data\s*/, '');

                    let survey = searchArrayWithActiveAndShow(surveyTitle, surveys, "title", "active", "showOnReport");
                    if(survey) {
                        reportArray.push(value);
                    }
                }
            });
        }
        reportArray.forEach((obj) => {
            filteredArray.push({
                label: obj,
                value: false
            });
        });

        if(filteredArray.length) {
            filteredArray.sort(function (a, b) {
                return (a["label"]).localeCompare(b["label"])
            });

            return filteredArray;
        } else {
            return "";
        }
    };

    const updateMultiSelectFilteredOption = (fetchedValue, filterValue) => {
        let filteredArray = [];
        fetchedValue.forEach((obj) => {
            let data = Object.assign({}, obj);

            if (data.type === filterValue) {
                filteredArray.push({
                    label: data.value,
                    value: false
                });
            }
        });

        if(filteredArray.length) {
            filteredArray.sort(function (a, b) {
                return (a["label"]).localeCompare(b["label"])
            });

            return filteredArray;
        } else {
            return "";
        }
    };

    const inputChangeHandler = (event) => {
        setDisableSubmit(false);
        setShowMessage(false);

        const {name, value} = event.currentTarget;

        setFormValue({
            ...formValue,
            [name]: value,
        });
    };

    const handleCheckboxChange = (event, newData, checked, count, name) => {
        setDisableSubmit(false);
        setShowMessage(false);

        if (name === "reportOption") {
            setReportOptionList(newData);
            setFormValue({...formValue, selectAllReportOption: checked, reportOptionCount: count});

            if (count > 0) {
                let newEvent = {
                    "target": {
                        "name": "reportOptionCount",
                        "value": count,
                    }
                };
                validateInputValue(newEvent);
            }
        } else if (name === "plant") {
            setPlantList(newData);
            let plantNames = getArrayValue(newData);
            let data = getSites(plantNames);
            setSiteList(data);
            setSitesList(data);
            setFormValue({
                ...formValue,
                selectAllPlant: checked,
                plantCount: count,
                selectAllSite: false,
                siteCount: 0
            });
        } else if (name === "site") {
            setSiteList(newData);
            setFormValue({...formValue, selectAllSite: checked, siteCount: count});
        } else if (name === "traumaLevel") {
            setTraumaLevelList(newData);
            setFormValue({...formValue, selectAllTraumaLevel: checked, traumaLevelCount: count});
        }
    };

    const validateInputValue = (event) => {
        const {checkValidate, currentFormState} = validateForm(event, inputValidation, fieldValidation);
        setFormValidation(checkValidate);
        setInputValidation(currentFormState);
    };

    const handleClick = (event) => {
        const name = event.currentTarget.getAttribute("name");

        if (name === "reportOption") {
            let flag = formValue.showReportOption;

            if(!flag) {
                let data = updateMultiSelectSearch(reportDropdown, "label", "", reportOptionList);
                setReportOptionList(data);
                let selectedAll = getSelectedAllFlag(data);
                setFormValue({...formValue, showReportOption: !flag, selectAllReportOption: selectedAll});
            } else {
                setFormValue({...formValue, showReportOption: !flag});
            }
        } else if (name === "plant") {
            let flag = formValue.showPlant;

            if(!flag) {
                let data = updateMultiSelectSearch(plantData, "_id", "", plantList);
                setPlantList(data);
                let selectedAll = getSelectedAllFlag(data);
                setFormValue({...formValue, showPlant: !flag, selectAllPlant: selectedAll});
            } else {
                setFormValue({...formValue, showPlant: !flag});
            }
        } else if (name === "site") {
            let flag = formValue.showSite;

            if(!flag) {
                let data = updateMultiSelectSearch(sitesList, "label", "", siteList);
                setSiteList(data);
                let selectedAll = getSelectedAllFlag(data);
                setFormValue({...formValue, showSite: !flag, selectAllSite: selectedAll});
            } else {
                setFormValue({...formValue, showSite: !flag});
            }
        } else if (name === "traumaLevel") {
            let flag = formValue.showTraumaLevel;

            if(!flag) {
                let data = updateMultiSelectSearch(traumaLevelAllList, "label", "", traumaLevelList);
                setTraumaLevelList(data);
                let selectedAll = getSelectedAllFlag(data);
                setFormValue({...formValue, showTraumaLevel: !flag, selectAllTraumaLevel: selectedAll});
            } else {
                setFormValue({...formValue, showTraumaLevel: !flag});
            }
        }
    };

    const handleSearch = (event) => {
        const {value, name} = event.currentTarget;

        if (name === "reportOption") {
            setFormValue({...formValue, selectAllReportOption: false});
            let data = updateMultiSelectSearch(reportDropdown, "label", value, reportOptionList);
            setReportOptionList(data);
            let selectedAll = getSelectedAllFlag(data);
            setFormValue({...formValue, selectAllReportOption: selectedAll});
        } else if (name === "plant") {
            let data = updateMultiSelectSearch(plantData, "_id", value, plantList);
            setPlantList(data);
            let selectedAll = getSelectedAllFlag(data);
            setFormValue({...formValue, selectAllPlant: selectedAll});
        } else if (name === "site") {
            let data = updateMultiSelectSearch(sitesList, "label", value, siteList);
            setSiteList(data);
            let selectedAll = getSelectedAllFlag(data);
            setFormValue({...formValue, selectAllSite: selectedAll});
        } else if (name === "traumaLevel") {
            let data = updateMultiSelectSearch(traumaLevelAllList, "label", "", traumaLevelList);
            setTraumaLevelList(data);
            let selectedAll = getSelectedAllFlag(data);
            setFormValue({...formValue, selectAllTraumaLevel: selectedAll});
        }
    };

    const getArrayValue = (data) => {
        let filteredArray = [];
        if (data && data.length) {
            data.forEach((obj) => {
                if (obj.value === true) {
                    filteredArray.push(obj.label);
                }
            });
        }

        return filteredArray.length ? filteredArray : "";
    };

    const submitHandler = async (event) => {
        event.preventDefault();

        const {checkValidate, currentFormState} = validateFormOnSave(formValue, inputValidation, fieldValidation);
        setFormValidation(checkValidate);
        setInputValidation(currentFormState);
        setDisableSubmit(true);
        if (!checkValidate) {
            return false;
        }
        let surveys = getReportName(reportOptionList);
        let selectedSiteDate = getArrayValue(siteList);
        let sites;

        if (surveys && !surveys.length) {
            setDisableSubmit(true);
            return false;
        }

        if (selectedSiteDate) {
            sites = selectedSiteDate;
        } else {
            sites = getAllArrayValue(siteList);
        }

        let siteNewData = [];
        siteData.forEach((siteObj) => {
            let data = sites.indexOf(siteObj.site);

            if (data !== -1) {
                siteNewData.push(siteObj);
            }
        });

        setDisableSubmit(true);
        let filteredArray = {
            "idnAffiliation": idnAffiliation,
            "plant": getArrayValue(plantList),
            "traumaLevel": getArrayValue(traumaLevelList),
            "sites": sites,
            "surveys": surveys,
            "startDate": getDateInUTC(startDate),
            "endDate": getDateInUTC(endDate),
        };

        let dataExportByAttribute = {};

        const response = await axiosFetch({
            axiosInstance: axios,
            method: 'post',
            url: '/getDataByAttributeReport',
            requestConfig: {
                data: filteredArray
            }
        });

        surveys.forEach((value) => {
            let surveyObject = searchArrayWithActive(value, props.surveysArray, "title", "active");

            if (value === 'Activities') {
                dataExportByAttribute["ActivityRawData"] = getLinenQualityReport(response.data.surveyData, siteData, "Activities", surveyObject);
            } else if (value === 'Add On Sales') {
                dataExportByAttribute["AddOnSalesData"] = getLinenQualityReport(response.data.surveyData, siteData, "Add On Sales", surveyObject);
            } else if (value === 'Client Satisfaction') {
                let clientSatRawData = getClientStatReport(response.data.surveyData, siteData, "Client Satisfaction", surveyObject);
                let clientSatRawDataWD = getLinenQualityReport(response.data.surveyData, siteData, "Client Satisfaction - No distribution", surveyObject);
                dataExportByAttribute["ClientSatRawData"] = [...clientSatRawData, ...clientSatRawDataWD];
            } else if (value === 'Linen Quality & Availability') {
                dataExportByAttribute["LinenQuality&Availability"] = getLinenQualityReport(response.data.surveyData, siteData, "Linen Quality & Availability", surveyObject);
            } else if (value === 'Linen Awareness') {
                dataExportByAttribute["LinenAwarenessData"] = getLinenQualityReport(response.data.surveyData, siteData, "Linen Awareness", surveyObject);
            } else if (value === 'Linen Committee') {
                dataExportByAttribute["LinenCommitteeData"] = getLinenQualityReport(response.data.surveyData, siteData, "Linen Committee", surveyObject);
            } else if (value === 'Linen Loss') {
                dataExportByAttribute["LinenLossRawData"] = getLinenQualityReport(response.data.surveyData, siteData, "Linen Loss", surveyObject);
            } else if (value === 'Facility Master Data') {
                dataExportByAttribute["MasterDataRawData"] = masterReportData(siteNewData);
            } else if (value === 'PAR') {
                dataExportByAttribute["PARRawData"] = getLinenQualityReport(response.data.surveyData, siteData, "PAR", surveyObject);
            } else if (value === 'Onsite Linen Inspection') {
                dataExportByAttribute["OnsiteLinenInspectionRawData"] = getOnSiteReport(response.data.surveyData, siteData, "Onsite Linen Inspection", surveyObject);
            } else if (value === 'QBR') {
                dataExportByAttribute["QBRData"] = getLinenQualityReport(response.data.surveyData, siteData, "QBR", surveyObject);
            } else if (value === 'Subcontractor End User') {
                dataExportByAttribute["SubContractorSurveyData"] = getLinenQualityReport(response.data.surveyData, siteData, "Subcontractor End User", surveyObject);
            } else if (value !== "Client Satisfaction - No distribution") {
                let keyTitle = value;

                if(value.length > 25) {
                    keyTitle = value.slice(0, 30);
                }
                dataExportByAttribute[keyTitle] = getLinenQualityReport(response.data.surveyData, siteData, value, surveyObject);
            }
        });

        if (surveys.indexOf("Scorecard") !== -1) {
            const scorecard = await axiosFetch({
                axiosInstance: axios,
                method: 'post',
                url: '/getScorecardPlantMapping',
                requestConfig: {
                    data: {
                        "site": sites,
                        "startDate": getDateInUTC(startDate),
                        "endDate": getDateInUTC(endDate),
                    }
                }
            });
            dataExportByAttribute["Scorecard"] = scorecardReportData(scorecard.data, siteData);
        }

        if (surveys.indexOf("Utilization") !== -1) {
            const utilization = await axiosFetch({
                axiosInstance: axios,
                method: 'post',
                url: '/getUtilizationPlantMapping',
                requestConfig: {
                    data: {
                        "site": sites,
                        "startDate": getDateInUTC(startDate),
                        "endDate": getDateInUTC(endDate),
                    }
                }
            });
            dataExportByAttribute["Utilization"] = utilizationReportData(utilization.data, siteData);
        }
        exportSurveyData(dataExportByAttribute, "DataExportByAttribute.xlsx");
        setDisableSubmit(true);
        setShowMessage(true);
    };

    const closeModalBox = () => {
        setFormValue({
            showPlant: false,
            selectAllPlant: false,
            plantCount: 0,
            showSite: false,
            selectAllSite: false,
            siteCount: 0,
            idnAffiliation: "",
            showBedType: false,
            selectAllBedType: false,
            bedTypeCount: 0,
            showClinicalEng: false,
            selectAllClinicalEng: false,
            clinicalEngCount: 0,
            showEvs: false,
            selectAllEvs: false,
            evsCount: 0,
            showFacilityGeo: false,
            selectAllFacilityGeo: false,
            facilityGeoCount: 0,
            showFacilityType: false,
            selectAllFacilityType: false,
            facilityTypeCount: 0,
            showFinancial: false,
            selectAllFinancial: false,
            financialCount: 0,
            showFN: false,
            selectAllFN: false,
            fNCount: 0,
            showGpoUtil: false,
            selectAllGpoUtil: false,
            gpoUtilCount: 0,
            showLdSoftware: false,
            selectAllLdSoftware: false,
            ldSoftwareCount: 0,
            showLinenCont: false,
            selectAllLinenCont: false,
            linenContCount: 0,
            showLinenDist: false,
            selectAllLinenDist: false,
            linenDistCount: 0,
            showLinenService: false,
            selectAllLinenService: false,
            linenServiceCount: 0,
            showLocation: false,
            selectAllLocation: false,
            locationCount: 0,
            showPatientTransport: false,
            selectAllPatientTransport: false,
            patientTransportCount: 0,
            showPlantOpe: false,
            selectAllPlantOpe: false,
            plantOpeCount: 0,
            showPrimaryTextile: false,
            selectAllPrimaryTextile: false,
            primaryTextileCount: 0,
            showState: false,
            selectAllState: false,
            stateCount: 0,
            showTraumaLevel: false,
            selectAllTraumaLevel: false,
            traumaLevelCount: 0,
            showUtilization: false,
            selectAllUtilization: false,
            utilizationCount: 0,
            showVolume: false,
            selectAllVolume: false,
            volumeCount: 0,
            showEscalationFlag: false,
            selectAllEscalationFlag: false,
            escalationFlagCount: 0,
            showReportOption: false,
            selectAllReportOption: false,
            reportOptionCount: 0,
            numOfBeds: 0,
            renewStartDate: new Date(),
            renewEndDate: new Date()
        });

        setPlantList(updateMultiSelectOption(plantData, "plant"));
        setBedTypeList(updateMultiSelectFilteredOption(frequencyData, "Type of Beds"));
        setClinicalEngList(updateMultiSelectFilteredOption(frequencyData, "Clinical Engineering"));
        setPlantOpMgmtList(updateMultiSelectFilteredOption(frequencyData, "Plant Operations Mgmt"));
        setPatientTransportList(updateMultiSelectFilteredOption(frequencyData, "Patient Transport"));
        setFnList(updateMultiSelectFilteredOption(frequencyData, "F&N"));
        setLinenServiceList(updateMultiSelectFilteredOption(frequencyData, "Linen Service"));
        setFacilityGeographyList(updateMultiSelectFilteredOption(frequencyData, "Facility Geography"));
        setLocationList(updateMultiSelectFilteredOption(frequencyData, "Location"));
        setGpoUtilizedList(updateMultiSelectFilteredOption(frequencyData, "GPO Utilized"));
        setUsesLdSoftwareList(updateMultiSelectFilteredOption(frequencyData, "Uses LD Software"));
        setEvsList(updateMultiSelectFilteredOption(frequencyData, "EVS"));
        setLinenDistMgmtList(updateMultiSelectFilteredOption(frequencyData, "Linen Dist Mgmt"));
        setLinenContractLiasonList(updateMultiSelectFilteredOption(frequencyData, "Linen Contract Liason"));
        setTraumaLevelList(updateMultiSelectFilteredOption(frequencyData, "Trauma Level"));
        setFinancialList(updateMultiSelectFilteredOption(frequencyData, "Financial"));
        setFacilityTypeList(updateMultiSelectFilteredOption(frequencyData, "Type of Facilities"));
        setEscalationFlagList(updateMultiSelectFilteredOption(frequencyData, "Escalation Flag"));
        setStateList(updateMultiSelectFilteredOption(frequencyData, "State"));
        setPrimaryTextileList(updateMultiSelectFilteredOption(frequencyData, "Primary Textile"));
        setUtilizationList(updateMultiSelectFilteredOption(frequencyData, "Utilization"));
        setVolumeList(updateMultiSelectFilteredOption(frequencyData, "Volume"));
        setItemList((frequencyData).filter(value => value.type === "Item Category"));
        setReportOptionList(updateMultiSelectFilteredOptionForReport(frequencyData, "Report Option", props.surveysArray));
        setIdnList([{"value": idnAffiliation}]);
        setSiteList("");

        setShowMessage(false);
        setFormValidation(true);
        setInputValidation({"reportOptionCount": true});
        props.handleClose();
    };

    return (
        <>
            <Loader isLoading={loading}/>
            <Modal show={props.showDataExport} onHide={closeModalBox} backdrop="static"
                   aria-labelledby="contained-modal-title-vcenter" centered className="reportModal">
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">{reportOption}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Message showMessage={showMessage}
                             response={response} error={error} loading={loading}
                             formValidation={formValidation} message="Exported Data Successfully"/>
                    <DataExportForm submitHandler={submitHandler} stateList={stateList}
                                    escalationFlagList={escalationFlagList}
                                    facilityTypeList={facilityTypeList} financialList={financialList}
                                    traumaLevelList={traumaLevelList}
                                    linenContractLiasonList={linenContractLiasonList}
                                    linenDistMgmtList={linenDistMgmtList}
                                    evsList={evsList} usesLdSoftwareList={usesLdSoftwareList}
                                    gpoUtilizedList={gpoUtilizedList}
                                    locationList={locationList} facilityGeographyList={facilityGeographyList}
                                    linenServiceList={linenServiceList} fnList={fnList}
                                    patientTransportList={patientTransportList}
                                    plantOpMgmtList={plantOpMgmtList} clinicalEngList={clinicalEngList}
                                    idnList={idnList} primaryTextileList={primaryTextileList}
                                    formValue={formValue} utilizationList={utilizationList}
                                    reportOptionList={reportOptionList}
                                    plantList={plantList} bedTypeList={bedTypeList} volumeList={volumeList}
                                    siteList={siteList} innerRef={innerRef}
                                    handleCheckboxChange={handleCheckboxChange} handleClick={handleClick}
                                    handleSearch={handleSearch} inputChangeHandler={inputChangeHandler}
                                    innerRefSite={innerRefSite} innerRefBedType={innerRefBedType}
                                    innerRefClinicalEng={innerRefClinicalEng} innerRefEvs={innerRefEvs}
                                    innerRefFacilityGeo={innerRefFacilityGeo}
                                    innerRefFacilityType={innerRefFacilityType}
                                    innerRefFN={innerRefFN} innerRefFinancial={innerRefFinancial}
                                    innerRefGpoUtil={innerRefGpoUtil} innerRefLdSoftware={innerRefLdSoftware}
                                    innerRefLinenCont={innerRefLinenCont} innerRefLinenDist={innerRefLinenDist}
                                    innerRefLinenService={innerRefLinenService} innerRefLocation={innerRefLocation}
                                    innerRefPatientTransport={innerRefPatientTransport}
                                    innerRefPlantOpe={innerRefPlantOpe}
                                    innerRefPrimaryTextile={innerRefPrimaryTextile} innerRefState={innerRefState}
                                    innerRefTraumaLevel={innerRefTraumaLevel}
                                    innerRefUtilization={innerRefUtilization}
                                    innerRefVolume={innerRefVolume} innerRefEscalationFlag={innerRefEscalationFlag}
                                    innerRefReportOption={innerRefReportOption} disableSubmit={disableSubmit}
                                    inputValidation={inputValidation} response={response} error={error}
                                    loading={loading} showMessage={showMessage} formValidation={formValidation}/>
                </Modal.Body>
            </Modal>
        </>
    )
};

export default DataExport;